<template>
  <div>
    <div class="d-flex mb-5 justify-space-between align-center">
      <p
        class="display-1 ma-0 text-uppercase"
        :style="
          $vuetify.breakpoint.mobile
            ? `text-align: center; font-size: 1.5rem!important; width: 100%`
            : ''
        "
      >
        {{ $t('title-my-container-release-order') }}
      </p>
    </div>

    <div class="pa-0">
      <div class="d-flex flex-column">
        <v-row
          class="my-4 align-center justify-space-between"
          no-gutters
        >
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 10 : 4"
            class="pr-6"
          >
            <v-card
              class="px-2"
              rounded="lg"
            >
              <v-card-text class="pa-0">
                <v-text-field
                  v-model="typedSearch"
                  :label="$t('label-cro-search')"
                  prepend-inner-icon="mdi-magnify"
                  type="text"
                  solo
                  flat
                  hide-details
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 4"
            v-if="!$vuetify.breakpoint.mobile"
          >
            <v-pagination
              v-model="page"
              :value="page"
              @input="onChangePage"
              :length="myContainerReleaseOrderListTotalPage"
            />
          </v-col>
        </v-row>
      </div>

      <v-pagination
        v-if="$vuetify.breakpoint.mobile"
        v-model="page"
        :value="page"
        @input="onChangePage"
        :length="myContainerReleaseOrderListTotalPage"
      />

      <div style="min-height: 500px">
        <v-card
          color="background"
          elevation="0"
          rounded="xl"
        >
          <v-card-text
            style="font-size: 11px"
            :class="{ 'd-none': $vuetify.breakpoint.mobile }"
          >
            <v-row no-gutters>
              <v-col
                cols="7"
                class="font-weight-bold text-uppercase"
              >
                {{ $t('col-container-release-order-info') }}
              </v-col>
              <v-col
                cols="2"
                class="text-center font-weight-bold text-uppercase"
              >
                {{ $t('col-status') }}
              </v-col>
              <v-col
                cols="3"
                class="text-center font-weight-bold text-uppercase"
              >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <template v-if="!isLoading">
          <MyContainerReleaseOrderListItem
            v-for="item in myContainerReleaseOrderList"
            :key="item.id"
            :item="item"
          />
        </template>

        <template v-if="isLoading">
          <v-card
            v-for="(item, i) in 5"
            :key="i"
            class="mt-2 pa-3"
            rounded="xl"
          >
            <v-row
              no-gutters
              class="align-center"
            >
              <v-col
                cols="8"
                class="text-truncate"
              >
                <v-skeleton-loader
                  :boilerplate="false"
                  type="list-item-three-line, list-item-three-line"
                />
              </v-col>
              <v-col
                cols="2"
                class="d-flex justify-center align-center"
              >
                <v-skeleton-loader
                  :boilerplate="false"
                  type="button"
                />
              </v-col>
              <v-col
                cols="2"
                class="text-center text-truncate"
              >
                <v-skeleton-loader
                  :boilerplate="false"
                  type="list-item-two-line"
                />
              </v-col>
            </v-row>
          </v-card>
        </template>
        <NoData v-if="!isLoading && myContainerReleaseOrderList.length === 0" />
      </div>

      <v-pagination
        class="mt-3"
        v-model="page"
        v-if="!isLoading"
        :value="page"
        @input="onChangePage"
        :length="myContainerReleaseOrderListTotalPage"
      />
    </div>
  </div>
</template>

<script>
import NoData from '@/components/shared/local/base/NoData.vue';
import { mapActions, mapState, mapMutations } from 'vuex';
import MyContainerReleaseOrderListItem from './MyContainerReleaseOrderListItem.vue';
import queryString from 'query-string';
import { debounce, pluck } from 'rxjs/operators';
import { timer } from 'rxjs';
export default {
  components: { MyContainerReleaseOrderListItem, NoData },
  data: () => ({
    isLoading: false,
    isLoadingFilters: false,
    isOpenFilters: false,
    page: 0,
    typedSearch: '',
    filterCount: 0,
  }),
  computed: {
    ...mapState('vsl/containerReleaseOrderV2', [
      'myContainerReleaseOrderList',
      'myContainerReleaseOrderListTotal',
      'myContainerReleaseOrderListTotalPage',
      'myContainerReleaseOrderListQueryParams',
    ]),
  },
  mounted() {
    const parsedQS = this.$route.query;
    this.typedSearch = parsedQS.search || '';

    this.$watchAsObservable('typedSearch')
      .pipe(
        pluck('newValue'),
        debounce(() => {
          this.isLoading = true;
          return timer(500);
        })
      )
      .subscribe(async (value) => {
        this.SET_MY_CONTAINER_RELEASE_ORDER_LIST_QUERY_PARAMS({
          search: value || undefined,
        });
      });

    this.SET_MY_CONTAINER_RELEASE_ORDER_LIST_QUERY_PARAMS({
      search: parsedQS.search,
      page: parsedQS.page ? Number(parsedQS.page) : 0,
      size: parsedQS.size ? Number(parsedQS.size) : 10,
    });
  },
  watch: {
    myContainerReleaseOrderListQueryParams: {
      handler(value) {
        console.log('[LOG] : value', value);
        if (value.page !== null && value.size !== null) {
          this.loadList();
        }

        const tempQP = { ...value };
        delete tempQP.page;
        delete tempQP.size;
        delete tempQP.search;
        const count = Object.values(tempQP).filter((item) => !!item).length;
        this.filterCount = count;

        this.page = value.page + 1 || 1;
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('vsl/containerReleaseOrderV2', [
      'SET_MY_CONTAINER_RELEASE_ORDER_LIST_QUERY_PARAMS',
    ]),
    ...mapActions('vsl/containerReleaseOrderV2', [
      'fetchMyContainerReleaseOrderList',
      'clearQueryParamsList',
    ]),

    async loadList() {
      try {
        this.isLoading = true;
        this.$router.push({
          query: {
            search: this.myContainerReleaseOrderListQueryParams.search,
            page: this.myContainerReleaseOrderListQueryParams.page,
            size: this.myContainerReleaseOrderListQueryParams.size,
          },
        });

        await this.fetchMyContainerReleaseOrderList();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },

    onChangePage(value) {
      this.SET_MY_CONTAINER_RELEASE_ORDER_LIST_QUERY_PARAMS({
        page: value - 1,
      });
    },

    handleToggleFilters() {
      this.isOpenFilters = !this.isOpenFilters;
    },

    handleClearFilter() {
      this.clearQueryParamsList();
      this.filterCount = 0;
    },

    setIsLoadingFilters(bool) {
      this.isLoadingFilters = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-pagination {
    justify-content: flex-end;
  }
  .v-skeleton-loader__list-item-three-line {
    height: 54px;
    width: 80%;
  }
  .v-skeleton-loader__button {
    width: 110px;
    height: 32px;
    border-radius: 16px;
  }
}
</style>
